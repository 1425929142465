import React from 'react'
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext'
import DemandTable from './DemandTable';
import DemandGraph from './DemandGraph';
import { TailSpin } from 'react-loader-spinner';
import Summary from './Summary';

const DemandScan = () => {
    const { demandTabs, setDemandTabs, activeDemandTab, setActiveDemandTab, demandLoading, tableData, demandGraphData,tableHeadValue } = useUpload();

    return (
        <div className='flex flex-col  w-full  items-center h-full text-xs text-bodyTextBlue '>
            <div className='flex justify-between  w-full items-center'>
                <div className='flex gap-6 items-center'>
                    {
                        demandTabs.map((item) => (
                            <div onClick={() => setActiveDemandTab(item)} className={` py-1 ${activeDemandTab === item ? "text-blue-500 border-b-2 border-blue-500" : ""} cursor-pointer text-lg`}>
                                {item}
                            </div>
                        ))
                    }

                </div>
            </div>
            {
                activeDemandTab === 'Summary' &&
                <Summary/>
            }
            {
                activeDemandTab !== 'Summary' &&

                <>
                    {
                        // demandLoading ?
                        //     <div className='h-full flex justify-center items-center'><TailSpin
                        //         visible={true}
                        //         height="40"
                        //         width="40"
                        //         color="#2B3674"
                        //         ariaLabel="tail-spin-loading"
                        //         radius="1"
                        //         wrapperStyle={{}}
                        //         wrapperClass=""
                        //     /></div> :
                        //     <>
                        //         <div className='flex w-full justify-end pr-8'>
                        //             <div className='flex items-center gap-4'>
                        //                 <div className='flex items-center gap-2'>
                        //                     <div className='h-4 w-12 bg-[#34a853] rounded-full' />
                        //                     <p>Volume</p>
                        //                 </div>
                        //                 {/* <div className='flex items-center gap-2'>
                        //                     <div className='h-4 w-12 bg-[#ffdb5c] rounded-full' />
                        //                     <p>Count</p>
                        //                 </div> */}
                        //             </div>
                        //         </div>
                        //         <div className='grid grid-cols-3 w-full gap-4 mt-2 '>
                        //             {
                        //                 demandGraphData && Object.keys(demandGraphData).map((data, index) => (

                        //                     <DemandGraph data={demandGraphData[data]} tableName={tableHeadValue[index]} />
                        //                 ))
                        //             }
                        //         </div>
                        //     </>

                    }
                    {
                        demandLoading ?
                            <div className='h-full flex justify-center items-center'><TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#2B3674"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /></div>
                            :
                            <DemandTable />
                    }
                </>
            }
        </div>
    )
}

export default DemandScan