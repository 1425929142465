import React, { createContext, useState, useContext, useEffect } from 'react';
import { useBase } from '../BaseContext';
import { message } from 'antd';
import api from '../../../../api';

const PlanningContext = createContext();

export const PlanningProvider = ({ children }) => {
  const [subSelectionFilterMap, setSubSelectionFilterMap] = useState(new Map());
  const [inATTFilterPopUp, setinATTFilterPopUp] = useState(false)
  const [filterATT, setFilterATT] = useState(null);
  const { setFilterString, filters, setFilters, checkBoxArray, setCheckBoxArray } = useBase();
  const [showOverlay, setShowOverlay] = useState(false);
  const [graphEditPopUp, setGraphEditPopUp] = useState(false)
  const { additionalFields, setAdditionalFields,selectedArea, setSelectedArea } = useBase();
  const [proposalData, setProposalData] = useState([])
  const [showAverage, setShowAverage] = useState(false)
  const [showSum, setShowSum] = useState(false)
  const [showGraphs, setShowGraphs] = useState({
    "engine" : true,
    "planner" : true,
    "budget" : true,
    "history":true
  })

  const submitFilters = () => {
    var filter_string = "";
    [...filters.keys()].map((item) => {
      filter_string += "&" + item + "=";
      filters.get(item).map((data, index) => {
        if (index < filters.get(item).length)
          filter_string += data + ","
        else
          filter_string += data
      })
    })

    setFilterString(encodeURI(filter_string.slice(0, filter_string.length - 1)));
    setShowOverlay(false)
  }


  const clearAllFilters = () => {
    setFilters(new Map());
    setCheckBoxArray([]);
    // Optionally, reset other states related to filters if necessary
  };


  const getProposals = async (details) => {
    try {
      if (additionalFields.length === 0) {
        const result = await api.get("/api/proposal/", {
          params: {
            IATT2: details[0],
            CATT2: details[2]
          }
        })
        setProposalData(result.data);
      }
      else {
        const indexIATT2 = additionalFields.indexOf("IATT2");
        const indexCATT2 = additionalFields.indexOf("CATT2");

        const result = await api.get("/api/proposal/", {
          params: {
            IATT2: details[indexIATT2],
            CATT2: details[indexCATT2]
          }
        })
        setProposalData(result.data);
      }
    }
    catch (e) {
      message.info("No Proposals are available")
    }
  }
  return (
    <PlanningContext.Provider value={{
      filters, setFilters,
      subSelectionFilterMap, setSubSelectionFilterMap,
      inATTFilterPopUp, setinATTFilterPopUp,
      filterATT, setFilterATT,
      checkBoxArray, setCheckBoxArray,
      submitFilters,
      showOverlay, setShowOverlay,
      clearAllFilters,
      graphEditPopUp, setGraphEditPopUp,
      getProposals,
      proposalData,
      showAverage, setShowAverage,
      showSum, setShowSum,
      showGraphs, setShowGraphs
    }}>
      {children}
    </PlanningContext.Provider>
  );
};

export const usePlan = () => useContext(PlanningContext);