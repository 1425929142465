import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const GrowthBarGraph = ({ data }) => {
    const years = [...new Set(data.map((item) => item.Year))];

    // Calculate total sales for each year by summing up sales from all items
    const totalSalesByYear = years.map((year) => {
        const totalSales = data
            .filter((item) => item.Year === year)
            .reduce((sum, item) => sum + item.total_sales, 0);
        return totalSales;
    });

    // Function to format numbers as K, M, B (thousands, millions, billions)
    const formatNumber = (value) => {
        if (value >= 1_000_000_000) {
            // If the value is an integer, show without decimal
            return value % 1 === 0 ? (value / 1_000_000_000).toFixed(0) + 'B' : (value / 1_000_000_000).toFixed(1) + 'B'; // Billions
        } else if (value >= 1_000_000) {
            // If the value is an integer, show without decimal
            return value % 1 === 0 ? (value / 1_000_000).toFixed(0) + 'M' : (value / 1_000_000).toFixed(1) + 'M'; // Millions
        } else if (value >= 1_000) {
            // If the value is an integer, show without decimal
            return value % 1 === 0 ? (value / 1_000).toFixed(0) + 'K' : (value / 1_000).toFixed(1) + 'K'; // Thousands
        }
        return value; // Return the value if it's less than 1,000
    };

    // Prepare dataset for the bar chart
    const chartData = {
        labels: years, // X-axis labels (Years)
        datasets: [
            {
                data: totalSalesByYear, // Aggregated total sales data for each year
                backgroundColor: '#ffdb5c', // Yellow color for the bars
                barThickness: 10, // Set bar thickness to 10
            },
        ],
    };

    const options = {
        responsive: true,

        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `Total Sales: ${formatNumber(tooltipItem.raw)}`,
                },
            },
            title: {
                display: true,
                text: 'Total Sales by Year',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    // Formatting y-axis labels
                    callback: function (value) {
                        return formatNumber(value);
                    },
                },
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default GrowthBarGraph;
