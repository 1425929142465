import React, { useEffect, useState } from 'react'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext'

const CommonExpandedRow = ({
    name,
    additionalFields,
    trasnposedData,
    data,
    midIndex,
    fields,
    tdateVar
}) => {
    const { showAverage,
        showSum } = usePlan();
    const [colSpanNumber, setColSpanNumber] = useState(0);

    // const [processedData, setProcessedData] = useState(null);

    useEffect(() => {
        if (showAverage && showSum) {
            setColSpanNumber(2)
        }
        else if (showAverage || showSum) {
            setColSpanNumber(1)
        }
        else {
            setColSpanNumber(0)
        }
    }, [showAverage, showSum])



    return (
        <tr className=' bg-slate-100 '>
            {
                additionalFields.length === 0 &&
                <td colSpan={4 + colSpanNumber} className='sticky p-1 min-w-[30px] shadow-col bg-violet-50 left-0 z-30  h-full  text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }
            {
                additionalFields.length !== 0 &&
                <td colSpan={additionalFields.length + 1 + colSpanNumber} className='sticky p-1 min-w-[30px] bg-violet-50 shadow-col  left-0 z-30  h-full text-bodyTextBlue  pl-3 text-sm  '>{name}</td>
            }


            {trasnposedData && trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {
                {
                    if (name === "Open Orders") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.OPEN_OQTY === null ? 0 : item.OPEN_OQTY}</td>
                        )
                    }
                    if (name === "Forecast (OC)") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 1
                            }).format(item.FQTY_USER > item.OPEN_OQTY ? item.FQTY_USER?item.FQTY_USER:0 : item.OPEN_OQTY?item.OPEN_OQTY:0)}</td>
                        )
                    }
                    if (name === "Quotations") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.QQTY === null ? 0 : item.QQTY}</td>
                        )
                    }
                    if (name === "POERQTY") {
                        return (
                            <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>{item.POERQTY === null ? 0 : item.POERQTY}</td>
                        )
                    }
                    if (name === "Year ago") {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>
                                {item.yearAgo === null ? "-" : Intl.NumberFormat('en-US', {
                                    notation: "compact",
                                    maximumFractionDigits: 1
                                }).format(item.yearAgo)}
                            </td>
                        )
                    }
                    else {
                        return (
                            <td colSpan={index === midIndex ? 2 : 1} className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>0</td>
                        )
                    }
                }
            })}
            {/* <td className='p-2 shadow-col min-w-[110px] max-w-[110px] bg-violet-50'>0</td> */}

        </tr>)
}

export default CommonExpandedRow



