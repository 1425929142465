import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';
import { useBase } from '../Layout/baseContext/BaseContext';
import api from '../../api';


const EditOverlay = ({ setOverlayFlag, changes, setChanges, getData, getGraphData, adjLoading, setAdjLoading  }) => {
  const {setPage, setNext, additionalFields,setIsFetchingPlan} = useBase();
  const [priceChangeReason, setPriceChangeReason] = useState("Price Change")
  const [adjustmentSplit, setAdjustmentSplit] = useState("Absolute")
  const [timeDisaggregation, setTimeDisaggregation] = useState("Planner Forecast")

  function replaceKeys(changes, additionalFields) {
    // Create a map to track the first occurrence of each prefix key
    const replacementsMap = {};
    const prefixes = ["I", "L", "C"];
  
    // Populate the replacements map with the first occurrences
    prefixes.forEach(prefix => {
      for (const field of additionalFields) {
        if (field.startsWith(prefix) && !replacementsMap[prefix]) {
          replacementsMap[prefix] = field; // Store the first occurrence
        }
      }
    });
  
    // Replace keys in the changes array based on the replacements map
    const updatedChanges = changes.map(change => {
      prefixes.forEach(prefix => {
        // Find the original keys to replace
        const originalKey = Object.keys(change).find(key => key.startsWith(prefix));
        if (originalKey) {
          const newKey = replacementsMap[prefix];
          // Replace the key if the new key exists
          change[newKey] = change[originalKey];
          delete change[originalKey]; // Remove the old key
        }
      });
      return change;
    });
  
    return updatedChanges;
  }

  const [submitLoading, setSubmitLoading] = useState(false)
  const onSubmit = async () => {
    setSubmitLoading(true)
    try {
      setPage(2)
      // setAdjLoading(true)
      setOverlayFlag(false)
      setNext(true)

      const generateActivityId = () => {
        return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
      };
      const activityId = generateActivityId();
      const username = localStorage.getItem("username");
      // if(additionalFields.length >= 3){
      //   const updatedChanges = replaceKeys(changes, additionalFields);
      // }
      // console.log(updatedChanges,"************");
      await api.post("/api/submitAdjustment/",
        {
          user: username,
          data: changes,
          priceChangeReason: priceChangeReason,
          adjustmentSplit,
          timeDisaggregation,
          activityId
        }
      )
      setIsFetchingPlan(false)
      setChanges([]);
      getData();
      getGraphData();
      localStorage.removeItem('posts');
      // setAdjLoading(false)
    } catch (error) {
      // alert(error)
      console.log(error);
    }
    setSubmitLoading(false)
  }
  return (
    <>

      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/6 h-5/6 bg-slate-50 shadow-2xl rounded-2xl  z-[1000]"
      >
        <div className='flex flex-col items-start w-full h-full'>
          <div className="flex flex-row py-2 px-6 border-b border-b-gray-200 justify-between font-medium text-lg bg-white w-full">
            <p>Make Adjustments</p>
            <p onClick={() => setOverlayFlag(false)} className='cursor-pointer hover:text-red-600'>

            <FontAwesomeIcon icon={faClose} />
            </p>
          </div>
          <div className="flex flex-row w-full items-start px-6 py-2 text-sm font-normal text-[#1B2559]">
            <div className="flex flex-col w-2/3 space-y-2 border-r-2 border-r-gray-200 pr-2">
              <p>Select New Value for adjustment and valid reason for the same</p>
              <div className="grid grid-cols-2 gap-y-8 gap-x-6">

                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Price change</label>
                  <select
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                  >
                    <option value="">Price Change</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>


                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Select adjustment split type</label>
                  <select
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                    onChange={(e) => { setPriceChangeReason(e.target.value) }}
                  >
                    <option value="">Absolute</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Select time disaggregation method</label>
                  <select
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                    onChange={(e) => { setAdjustmentSplit(e.target.value) }}
                  >
                    <option value="">Planner forecast/Adjusted Sales pitch</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Select a disaggregation method</label>
                  <select
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                    onChange={(e) => { setTimeDisaggregation(e.target.value) }}
                  >
                    <option value="">Planner forecast/Adjusted sales pitch</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
                {/*<div>
                <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Disaggregation method</label>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="option1"
                    name="firstName"
                    value="option1"
                    className="appearance-none border border-gray-300 rounded-full h-4 w-4 checked:bg-black checked:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <label htmlFor="option1">Total quantity</label>
                  <input
                    type="radio"
                    id="option2"
                    name="firstName"
                    value="option2"
                    className="appearance-none border border-gray-300 rounded-full h-4 w-4 checked:bg-black checked:border-gray-300 checked:border-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <label htmlFor="option2">Baseline</label>
                </div>
  </div>*/}




              </div>
              <label class="inline-flex items-center py-2 cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ms-3 text-sm font-medium text-gray-900 ">Add new event</span>
              </label>
              <div className="grid grid-cols-2 gap-y-4 gap-x-6">
                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Event name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                    placeholder="Enter"
                  />
                </div>
                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Event Scope</label>
                  <select
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                  >
                    <option value="">Subgroup</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Enter Calender Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                    placeholder="Enter"
                  />
                </div>
                <div className='flex flex-row space-x-2 justify-between items-center'>
                  <div>
                    <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Enter Calender Name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                      placeholder="Enter"
                    />
                  </div>
                  <div>
                    <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Enter Calender Name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="mt-1 p-2 border border-gray-200 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                      placeholder="Enter"
                    />
                  </div>
                </div>
                {/*<div className='flex flex-row space-x-2 justify-between items-center'>
                <div className="relative">
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Window Minutes</label>
                  <div className="flex items-center border border-gray-200 rounded-xl bg-white shadow-sm">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-xl sm:text-sm"
                      placeholder="Enter"
                    />
                    <span className="px-2 text-[#A3AED0]">days</span>
                  </div>
                </div>
                <div className="relative">
                  <label htmlFor="firstName" className="block text-xs px-2 font-medium text-[#A3AED0]">Window Plus</label>
                  <div className="flex items-center border border-gray-200 rounded-xl bg-white shadow-sm">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-xl sm:text-sm"
                      placeholder="Enter"
                    />
                    <span className="px-2 text-[#A3AED0]">days</span>
                  </div>
                </div>

              </div>*/}
              </div>
            </div>
            <div className="flex flex-col w-1/3 px-2 space-y-2">
              <p className='text-black font-medium'>Select who to post to</p>
              <div className="relative">
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="flex items-center border border-gray-200 rounded-xl bg-white shadow-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-2 text-blue-400"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    className="p-2 focus:outline-none rounded-xl focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm pl-2"
                    placeholder="Search and add from your team"
                  />
                </div>
                <p className='text-gray-400 mt-2'>Or select individuals mapped to this entry</p>
                <div>

                  <div className="flex flex-col rounded-xl space-y-3  shadow-sm p-2">
                    <div className="flex items-center mb-1">
                      <input type="checkbox" id="option1" name="option1" className="mr-2" />
                      <label htmlFor="option1">All</label>
                    </div>
                    <div className="flex items-center mb-1">
                      <input type="checkbox" id="option2" name="option2" className="mr-2" />
                      <label htmlFor="option2">Demand Planner</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" id="option3" name="option3" className="mr-2" />
                      <label htmlFor="option3">Sales Manager</label>
                    </div>
                    <div className="flex items-center">
                      <input type="checkbox" id="option3" name="option3" className="mr-2" />
                      <label htmlFor="option3">Sales Manager 1</label>
                    </div>
                  </div>

                </div>

              </div>

              <div className='flex flex-col space-y-2'>
                <p>Some insights to assist you</p>
                <div className='flex flex-row space-x-4 px-4 py-3 rounded-lg bg-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5">
                    <path d="M13 2L8 11h3l-1 9 9-10h-4l1-9z"></path>
                  </svg>
                  <p className='text-blue-500 font-semibold' >Insight 1</p>

                </div>
                <div className='flex flex-row space-x-4 px-4 py-3 rounded-lg bg-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5">
                    <path d="M13 2L8 11h3l-1 9 9-10h-4l1-9z"></path>
                  </svg>
                  <p className='text-blue-500 font-semibold' >Insight 2</p>

                </div>
                <div className='flex flex-row space-x-4 px-4 py-3 rounded-lg bg-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5">
                    <path d="M13 2L8 11h3l-1 9 9-10h-4l1-9z"></path>
                  </svg>
                  <p className='text-blue-500 font-semibold' >Insight 3</p>

                </div>
                <div className='flex flex-row space-x-4 px-4 py-3 rounded-lg bg-white'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5">
                    <path d="M13 2L8 11h3l-1 9 9-10h-4l1-9z"></path>
                  </svg>
                  <p className='text-blue-500 font-semibold' >Insight 4</p>

                </div>
              </div>




            </div>
          </div>

          <div className='mt-4 py-4 bg-white w-full flex flex-row px-4 justify-end' >
            <div className='flex flex-row space-x-4 items-center text-white'>
              {/* <button className='bg-gray-200 border border-gray-300 text-gray-700 p-2 rounded-lg' onClick={() => setOverlayFlag(false)}>Cancel</button>
              <button className='bg-blue-500 border border-gray-600 p-2 rounded-lg' onClick={onSubmit}>Save Adjustments</button> */}


              <Button type='primary' className='bg-slate-400 hover:!bg-slate-500' onClick={() => setOverlayFlag(false)}>Cancel</Button>
              <Button loading={submitLoading} type='primary' onClick={onSubmit}>Save Adjustments</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOverlay;
