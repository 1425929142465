import React from 'react'
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext'
import DemandGraph from './DemandGraph';
import GrowthLineGraph from './GrowthLineGraph';

const Summary = () => {
    const { demandReport,
        itemGraphData,
        customerGraphData,
        locationGraphData } = useUpload();

    


    //pareto
    const pareto_product_count = demandReport['Pareto_Analysis']['Products'].length
    const pareto_location_count = demandReport['Pareto_Analysis']['Locations'].length
    const pareto_customer_count = demandReport['Pareto_Analysis']['Customers'].length

    //growth and trend
    const short_term_product_growth_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_product_growth_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "LONG-TERM GROWTH").length;

    const short_term_location_growth_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_location_growth_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "LONG-TERM GROWTH").length;

    const short_term_customer_growth_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "SHORT-TERM GROWTH").length;
    const long_term_customer_growth_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "LONG-TERM GROWTH").length;


    const short_term_product_growth = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_product = short_term_product_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_product_growth = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_product = long_term_product_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const short_term_location_growth = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_location = short_term_location_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_location_growth = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_location = long_term_location_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);


    const short_term_customer_growth = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "SHORT-TERM GROWTH")
    const top_5_short_term_customer = short_term_customer_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);

    const long_term_customer_growth = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG GROWTH" || item.trend === "LONG-TERM GROWTH")
    const top_5_long_term_customer = long_term_customer_growth
        .sort((a, b) => b.total_sales - a.total_sales)
        .slice(0, 5);


    //decline
    const short_term_product_decline_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_product_decline_count = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    const short_term_location_decline_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_location_decline_count = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    const short_term_customer_decline_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "SHORT-TERM DECLINE").length;
    const long_term_customer_decline_count = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE").length + demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "LONG-TERM DECLINE").length;

    // Short-Term and Long-Term Product Decline (Bottom 5)
    const short_term_product_decline = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_product = short_term_product_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Product Decline (Bottom 5)
    const long_term_product_decline = demandReport['Trend_Analysis']['Products'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_product = long_term_product_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Short-Term Location Decline (Bottom 5)
    const short_term_location_decline = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_location = short_term_location_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Location Decline (Bottom 5)
    const long_term_location_decline = demandReport['Trend_Analysis']['Locations'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_location = long_term_location_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Short-Term Customer Decline (Bottom 5)
    const short_term_customer_decline = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "SHORT-TERM DECLINE")
    const bottom_5_short_term_customer = short_term_customer_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);

    // Long-Term Customer Decline (Bottom 5)
    const long_term_customer_decline = demandReport['Trend_Analysis']['Customers'].filter(item => item.trend === "STRONG DECLINE" || item.trend === "LONG-TERM DECLINE")
    const bottom_5_long_term_customer = long_term_customer_decline
        .sort((a, b) => a.total_sales - b.total_sales)  // Sort in ascending order for bottom 5
        .slice(0, 5);


    //volatility

    // Function to get counts and top 5 entities for a given volatility segment and category
    const getVolatilityData = (data, category, segment) => {
        const items = data[category].filter(item => item.volatility_segment === segment);
        const count = items.length;
        const top5 = items.sort((a, b) => b.volume - a.volume).slice(0, 5);
        return { count, top5 };
    };

    // Products
    const { count: low_volatile_product_count, top5: top_5_low_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'VOLATILITY_LOW'
    );
    const { count: med_volatile_product_count, top5: top_5_med_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'VOLATILITY_MEDIUM'
    );
    const { count: high_volatile_product_count, top5: top_5_high_volatile_products } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Products', 'VOLATILITY_HIGH'
    );

    // Locations
    const { count: low_volatile_location_count, top5: top_5_low_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'VOLATILITY_LOW'
    );
    const { count: med_volatile_location_count, top5: top_5_med_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'VOLATILITY_MEDIUM'
    );
    const { count: high_volatile_location_count, top5: top_5_high_volatile_locations } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Locations', 'VOLATILITY_HIGH'
    );

    // Customers
    const { count: low_volatile_customer_count, top5: top_5_low_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'VOLATILITY_LOW'
    );
    const { count: med_volatile_customer_count, top5: top_5_med_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'VOLATILITY_MEDIUM'
    );
    const { count: high_volatile_customer_count, top5: top_5_high_volatile_customers } = getVolatilityData(
        demandReport['Volatility_Classification'], 'Customers', 'VOLATILITY_HIGH'
    );



    //age

    // Reusable function to get counts and top 5 entities
    const getAgeData = (data, category, segmentKey, segmentValue) => {
        const items = data[category].filter(item => item[segmentKey] === segmentValue);
        const count = items.length;
        const top5 = items.sort((a, b) => b.volume - a.volume).slice(0, 5);
        return { count, top5 };
    };

    // Products
    const { count: new_age_product_count, top5: top_5_new_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_product_count, top5: top_5_mid_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_product_count, top5: top_5_mid_mat_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_product_count, top5: top_5_long_age_products } = getAgeData(
        demandReport['Age_Classification'], 'Products', 'ISEGMENT1', 'LONG_AGE'
    );

    // Locations
    const { count: new_age_location_count, top5: top_5_new_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_location_count, top5: top_5_mid_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_location_count, top5: top_5_mid_mat_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_location_count, top5: top_5_long_age_locations } = getAgeData(
        demandReport['Age_Classification'], 'Locations', 'LSEGMENT1', 'LONG_AGE'
    );

    // Customers
    const { count: new_age_customer_count, top5: top_5_new_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'NEW_AGE'
    );
    const { count: mid_age_customer_count, top5: top_5_mid_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'MID_AGE'
    );
    const { count: mid_mat_age_customer_count, top5: top_5_mid_mat_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'MID_MATURED_AGE'
    );
    const { count: long_age_customer_count, top5: top_5_long_age_customers } = getAgeData(
        demandReport['Age_Classification'], 'Customers', 'CSEGMENT1', 'LONG_AGE'
    );




    //phased out 
    const phased_out_products = demandReport['Phased_Out']['Products']
    const phased_out_location = demandReport['Phased_Out']['Locations']
    const phased_out_customer = demandReport['Phased_Out']['Customers']

    //new entities 
    const new_products = demandReport['New_Entities']['Products']
    const new_location = demandReport['New_Entities']['Locations']
    const new_customer = demandReport['New_Entities']['Customers']


    //sporadicity
    const low_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "Low sporadicity").length
    const low_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "Low sporadicity")
    const top_5_low_sp_product = low_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);



    const med_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "Medium sporadicity").length;
    const med_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "Medium sporadicity");
    const top_5_med_sp_product = med_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_product_count = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "High sporadicity").length;
    const high_sp_product = demandReport['Sporadicity_Classification']['Products'].filter(item => item.sporadicity_segment === "High sporadicity");
    const top_5_high_sp_product = high_sp_product
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);


    const low_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "Low sporadicity").length;
    const low_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "Low sporadicity");
    const top_5_low_sp_location = low_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const med_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "Medium sporadicity").length;
    const med_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "Medium sporadicity");
    const top_5_med_sp_location = med_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_location_count = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "High sporadicity").length;
    const high_sp_location = demandReport['Sporadicity_Classification']['Locations'].filter(item => item.sporadicity_segment === "High sporadicity");
    const top_5_high_sp_location = high_sp_location
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);


    const low_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "Low sporadicity").length;
    const low_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "Low sporadicity");
    const top_5_low_sp_customer = low_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const med_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "Medium sporadicity").length;
    const med_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "Medium sporadicity");
    const top_5_med_sp_customer = med_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);

    const high_sp_customer_count = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "High sporadicity").length;
    const high_sp_customer = demandReport['Sporadicity_Classification']['Customers'].filter(item => item.sporadicity_segment === "High sporadicity");
    const top_5_high_sp_customer = high_sp_customer
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 5);




    // // Retrieve demandReport from localStorage
    // const demandReportString = localStorage.getItem("demandReport");

    // let demandReport = null;

    // if (demandReportString) {
    //     try {
    //         // Parse the stored string into a JSON object
    //         demandReport = JSON.parse(demandReportString);
    //         console.log(demandReport);
    //     } catch (error) {
    //         console.error("Error parsing demandReport from localStorage:", error);
    //     }
    // } else {
    //     console.warn("No demandReport found in localStorage");
    // }
    return (
        <>
            {
                demandReport !== null &&
                <div className='w-full flex flex-col text-sm mt-3 h-full overflow-y-scroll scroll text-slate-500'>
                    <div className='bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md '>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Year Over-Growth across business</h3>
                        <p>Based on the Data we received your year over year growth across products is <span className={`${demandReport['Year_Over_Growth_Text'] >= 0 ? "text-green-500" : "text-red-500"}`}>{Number(demandReport['Year_Over_Growth_Text']).toFixed(2)} %</span></p>
                        <div className='w-fit mt-2 bg-slate-100 p-4 rounded-md'>

                            <GrowthLineGraph data={demandReport['Year_Over_Growth_Data']} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>

                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Highest Growing Products, Locations and Customer </h3>

                        <div className='grid grid-cols-3 gap-2'>
                            <div>

                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Products</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Products'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['ITEM']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Locations</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Locations'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['LOCATION']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='grid grid-cols-2 bg-white '>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Customers</p>
                                    <p className='text-base font-semibold mt-1 text-slate-600 px-3 py-1 border  bg-slate-200'>Sales</p>
                                    {
                                        demandReport['Top_Growing_Entities']['Customers'].map((data) => (
                                            <>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['CUSTOMER']}</p>
                                                <p className='border bg-slate-50 px-3 py-1'>{data['total_sales']}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <p className='mt-2'>We have done a Pareto Analysis of your sales history and we see that the <span className='font-bold'>{pareto_product_count}</span> products contribute to 80% of your Sales</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top Products and their contribution to sales </p> */}

                        <p className='mt-2'>While if we look at customers then only <span className='font-bold'>{pareto_customer_count}</span> customer buy 80% of your products</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top Customers and their contribution to sales </p> */}


                        <p className='mt-2'>And we saw that 80% of the sales is done from only <span className='font-bold'>{pareto_location_count}</span>  locations</p>
                        {/* <p className='font-bold'>Side Bar Graph Showing the top locations and their contribution to sales </p> */}
                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT2']} tableName={"Product Volume"} />
                            <DemandGraph data={customerGraphData['CSEGMENT2']} tableName={"Customer Volume"} />
                            <DemandGraph data={locationGraphData['LSEGMENT2']} tableName={"Location Volume"} />
                        </div>

                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Trend</h3>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Growth</h6>
                        {
                            !(short_term_product_growth_count === 0 && long_term_product_growth_count === 0) &&
                            <>
                                <p>There are <span className='font-bold'>{short_term_product_growth_count}</span> products which show a short term growth and <span className='font-bold'>{long_term_product_growth_count}</span> with long term growth</p>
                                <p>Below are the top short-term and long-term growth products</p>
                                <div>
                                    <div className='grid grid-cols-2 gap-4 w-fit '>
                                        {
                                            short_term_product_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2 '>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>

                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {
                                            long_term_product_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2 '>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className=' font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_customer_growth_count === 0 && long_term_customer_growth_count === 0) &&
                            <>
                                <p className='mt-2'>There are <span className='font-bold'>{short_term_customer_growth_count}</span> customers which show a short term growth and <span className='font-bold'>{long_term_customer_growth_count}</span> with long term growth</p>
                                <p>Below are the top short-term and long-term growth customers</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_customer_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_customer_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_location_growth_count === 0 && long_term_location_growth_count === 0) &&
                            <>
                                <p className='mt-2'>There are <span className='font-bold'>{short_term_location_growth_count}</span> locations which show a short term growth and <span className='font-bold'>{long_term_location_growth_count}</span> with long term growth</p>
                                <p>Below are the top short-term and long-term growth locations</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_location_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_short_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_location_growth_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {top_5_long_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {/* </div> */}
                        {/* <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Decline</h3> */}
                        <h6 className='text-base font-semibold text-slate-600 mt-4'>Decline</h6>

                        {
                            !(short_term_product_decline_count === 0 && long_term_product_decline_count === 0) &&
                            <>
                                <p className='mt-2'>There are <span className='font-bold'>{short_term_product_decline_count}</span> products which show a short term decline and <span className='font-bold'>{long_term_product_decline_count}</span> with long term decline</p>
                                <p>Below are the top short-term and long-term decline products</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_product_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_product_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_product.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_customer_decline_count === 0 && long_term_customer_decline_count === 0) &&
                            <>
                                <p className='mt-2'>There are <span className='font-bold'>{short_term_customer_decline_count}</span> customers which show a short term decline and <span className='font-bold'>{long_term_customer_decline_count}</span> with long term decline</p>
                                <p>Below are the top short-term and long-term decline customers</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_customer_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_customer_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_customer.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </>
                        }
                        {
                            !(short_term_location_decline_count === 0 && long_term_location_decline_count === 0) &&
                            <>
                                <p className='mt-2'>There are <span className='font-bold'>{short_term_location_decline_count}</span> locations which show a short term decline and <span className='font-bold'>{long_term_location_decline_count}</span> with long term decline</p>
                                <p>Below are the top short-term and long-term decline locations</p>
                                <div>
                                    <div className='grid grid-cols-2 w-fit gap-4'>
                                        {
                                            short_term_location_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Short-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_short_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                        {
                                            long_term_location_decline_count !== 0 &&
                                            <div className='bg-white'>
                                                <div className='grid grid-cols-2'>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long-Term</h6>
                                                    <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Sales</h6>
                                                    {bottom_5_long_term_location.map((data) => (
                                                        <>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                            <p className='border px-3 py-1 bg-slate-50'>{data.total_sales}</p>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        }
                                    </div>

                                </div>

                            </>
                        }
                        <div className='grid grid-cols-3 w-full gap-4 mt-4 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT6']} tableName={"Product Trend"} />
                            <DemandGraph data={customerGraphData['CSEGMENT6']} tableName={"Customer Trend"} />
                            <DemandGraph data={locationGraphData['LSEGMENT6']} tableName={"Location Trend"} />
                        </div>
                        `
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Volatility</h3>
                        <p className=''>Volatility represents the consistency in terms of buying pattern of your products by your customer from your shipping locations. </p>

                        <p>We have observed that you have <span className='font-bold'>{high_volatile_product_count}</span> products in the highly volatile range while <span className='font-bold'>{med_volatile_product_count}</span> products in mid-range and <span className='font-bold'>{low_volatile_product_count}</span> products in the low volatility range.  </p>
                        {
                            !(high_volatile_product_count === 0 && med_volatile_product_count === 0 && low_volatile_product_count === 0) &&
                            <p className=''>Below are top products of each category</p>
                        }

                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_high_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Med volatile Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_med_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_low_volatile_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>


                        <p className='mt-2'>From the customer’s buying patterns, we have observed that you have <span className='font-bold'>{high_volatile_customer_count}</span> customers in the highly volatile range while <span className='font-bold'>{med_volatile_customer_count}</span> customers in mid-range and <span className='font-bold'>{low_volatile_customer_count}</span> customers in the low volatility range.  </p>
                        {
                            !(high_volatile_customer_count === 0 && med_volatile_customer_count === 0 && low_volatile_customer_count === 0) &&
                            <p>Below are top customers of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_high_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Med volatile Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_med_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_low_volatile_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>

                        <p className='mt-2'>The demand on <span className='font-bold'>{high_volatile_location_count}</span> locations is in the highly volatile range while <span className='font-bold'>{med_volatile_location_count}</span> locations in mid-range and <span className='font-bold'>{low_volatile_location_count}</span> locations in the low volatility range.  </p>
                        {
                            !(high_volatile_location_count === 0 && med_volatile_location_count === 0 && low_volatile_location_count === 0) &&
                            <p>Below are top locations of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High volatile Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_high_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Med volatile Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_med_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_volatile_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low volatile Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_low_volatile_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>


                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT3']} tableName={"Product Volatility"} />
                            <DemandGraph data={customerGraphData['CSEGMENT3']} tableName={"Customer Volatility"} />
                            <DemandGraph data={locationGraphData['LSEGMENT3']} tableName={"Location Volatility"} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Age</h3>
                        <p>Age represents the maturity of the product in the market or the customer lifetime with you and the supply location’s service history </p>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Products</h6>
                        <p>We observed that you have <span className='font-bold'>{long_age_product_count}</span> products which are of type long age meaning they have more than 25 months of history
                            while <span className='font-bold'>{mid_age_product_count}</span> products which are of type mid age meaning they have 25 months of history
                            while <span className='font-bold'>{mid_mat_age_product_count}</span> products which are of type mid matured age meaning they have 13 months of history
                            while <span className='font-bold'>{new_age_product_count}</span> products which are of type new age meaning they have 3 months of history
                        </p>
                        {
                            !(long_age_product_count === 0 && mid_age_product_count && mid_mat_age_product_count && new_age_product_count) &&
                            <p>Below are the top products from each category</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {
                                top_5_new_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New age Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_new_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_mid_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid age Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_mid_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_mid_mat_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid Matured age Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_mid_mat_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_long_age_products.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long age Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_long_age_products.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>
                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Customers</h6>
                        <p>We observed that you have <span className='font-bold'>{long_age_customer_count}</span> customers which are of type long age meaning they have have more than 25 month of history
                            while <span className='font-bold'>{mid_age_customer_count}</span> customers which are of type mid age meaning they have 25 month of history
                            while <span className='font-bold'>{mid_mat_age_customer_count}</span> customers which are of type mid matured age meaning they have 13 month of history
                            while <span className='font-bold'>{new_age_customer_count}</span> customers which are of type new age meaning they have 3 month of history
                        </p>
                        {
                            !(long_age_customer_count === 0 && mid_age_customer_count && mid_mat_age_customer_count && new_age_customer_count) &&
                            <p>Below are the top customers from each category</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {
                                top_5_new_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New Age Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_new_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_mid_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid Age Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_mid_mat_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid Matured Age Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_mat_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_long_age_customers.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long Age Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_long_age_customers.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                        <h6 className='text-base font-semibold mt-1 text-slate-600'>Locations</h6>
                        <p>We observed that you have <span className='font-bold'>{long_age_location_count}</span> location which are of type long age meaning they have have more than 25 month of history
                            while <span className='font-bold'>{mid_age_location_count}</span> location which are of type mid age meaning they have 25 month of history
                            while <span className='font-bold'>{mid_mat_age_location_count}</span> location which are of type mid matured age meaning they have 13 month of history
                            while <span className='font-bold'>{new_age_location_count}</span> location which are of type new age meaning they have 3 month of history
                        </p>
                        {
                            !(long_age_location_count === 0 && mid_age_location_count && mid_mat_age_location_count && new_age_location_count) &&
                            <p>Below are the top locations from each category</p>
                        }
                        <div className='grid grid-cols-4 gap-1'>
                            {
                                top_5_new_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>New Age Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_new_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_mid_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid Age Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_mid_mat_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid Matured Age Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_mid_mat_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_long_age_locations.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Long Age Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_long_age_locations.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT1']} tableName={"Product Age"} />
                            <DemandGraph data={customerGraphData['CSEGMENT1']} tableName={"Customer Age"} />
                            <DemandGraph data={locationGraphData['LSEGMENT1']} tableName={"Location Age"} />
                        </div>
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Phased Out</h3>
                        {phased_out_products.length === 0 && phased_out_customer.length === 0 && phased_out_location.length === 0 &&
                            <p>There are no products, customers or location which are phased out this year</p>
                        }
                        {
                            !(phased_out_products.length === 0 && phased_out_customer.length === 0 && phased_out_location.length === 0) &&
                            <div className='grid grid-cols-3 gap-2'>
                                {phased_out_products.length !== 0 &&
                                    <div className=''>
                                        <p>Here are the products which are phased out this year</p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_products.length !== 0 && phased_out_products.map((data) => (
                                                    <p>{data['ITEM']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    phased_out_customer.length !== 0 &&
                                    <div className=''>

                                        <p>Here are few customers which are phased out this year. </p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_customer.length !== 0 && phased_out_customer.map((data) => (
                                                    <p>{data['CUSTOMER']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    phased_out_location.length !== 0 &&
                                    <div className=''>

                                        <p>Here are few locations which are phased out this year. </p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                             {
                                                phased_out_location.length !== 0 && phased_out_location.map((data) => (
                                                    <p>{data['LOCATION']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>New</h3>
                        {new_products.length === 0 && new_location.length === 0 && new_customer.length === 0 &&
                            <p>There are no new products, customers or location which are added this year</p>
                        }
                        {
                            !(new_products.length === 0 && new_location.length === 0 && new_customer.length === 0) &&
                            <div className='grid grid-cols-3 gap-2'>
                                {
                                    new_products.length !== 0 &&
                                    <div>
                                        <p>Here are some products which started selling recently </p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>                                            {
                                                new_products.length !== 0 && new_products.map((data) => (
                                                    <p>{data['ITEM']}</p>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    new_location.length !== 0 &&

                                    <div>
                                        <p>Here are some new location you have added this year  </p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>
                                                {
                                                    new_location.length !== 0 && new_location.map((data) => (
                                                        <p>{data['LOCATION']}</p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    new_customer.length !== 0 &&

                                    <div>
                                        <p>Here are some new customer you have added this year </p>
                                        <div className='flex flex-col max-h-44  bg-slate-100 p-3 rounded-md'>
                                            <div className='flex flex-col h-full overflow-y-scroll scroll'>
                                                {
                                                    new_customer.length !== 0 && new_customer.map((data) => (
                                                        <p>{data['CUSTOMER']}</p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className='mt-2 bg-white box-shadow-summary px-4 pb-4 pt-3 rounded-md'>
                        <h3 className='text-lg font-semibold mb-2 text-bodyTextBlue'>Sporadicity</h3>
                        <p>Sporadicity shows the frequency of sales, if a product sells alternate months then the sporadicity is 50 % since it sells 50 % of the times. </p>
                        <p className='mt-1'>We have observed that you have <span className='font-bold'>{high_sp_product_count}</span> products with very high sporadicity and <span className='font-bold'>{med_sp_product_count}</span> products with medium sporadicity and <span className='font-bold'>{low_sp_product_count}</span> products with low sporadicity </p>
                        {
                            !(high_sp_product_count === 0 && med_sp_product_count === 0 && low_sp_product_count === 0) &&
                            <p>Below are top products of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_high_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_med_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Medium sporadicity Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_med_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                            {
                                top_5_low_sp_product.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity Products</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volumne</h6>
                                        {top_5_low_sp_product.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.ITEM}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            }
                        </div>
                        <p className='mt-1'>We have observed that you have <span className='font-bold'>{high_sp_customer_count}</span> customers with very high sporadicity and <span className='font-bold'>{med_sp_customer_count}</span> customers with medium sporadicity and <span className='font-bold'>{low_sp_customer_count}</span> customers with low sporadicity </p>
                        {
                            !(high_sp_customer_count === 0 && med_sp_customer_count === 0 && low_sp_customer_count === 0) &&
                            <p>Below are top customers of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_med_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Mid sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_low_sp_customer.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity Customers</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_sp_customer.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.CUSTOMER}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                        <p className='mt-1'>We have observed that you have <span className='font-bold'>{high_sp_location_count}</span> locations with very high sporadicity and <span className='font-bold'>{med_sp_location_count}</span> locations with medium sporadicity and <span className='font-bold'>{low_sp_location_count}</span> locations with low sporadicity </p>
                        {
                            !(high_sp_location_count === 0 && med_sp_location_count === 0 && low_sp_location_count === 0) &&
                            <p>Below are top locations of each category</p>
                        }
                        <div className='grid grid-cols-3 gap-2'>
                            {
                                top_5_high_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>High sporadicity Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_high_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_med_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Medium sporadicity Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_med_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                top_5_low_sp_location.length !== 0 &&
                                <div className='bg-white'>
                                    <div className='grid grid-cols-2'>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Low sporadicity Locations</h6>
                                        <h6 className='font-semibold mt-1 text-slate-600 px-3 border bg-slate-200'>Volume</h6>
                                        {top_5_low_sp_location.map((data) => (
                                            <>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.LOCATION}</p>
                                                <p className='border px-3 py-1 bg-slate-50'>{data.volume}</p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='grid grid-cols-3 w-full gap-4 mt-2 bg-slate-100 p-4 rounded-md'>
                            <DemandGraph data={itemGraphData['ISEGMENT5']} tableName={"Product Sporadicity"} />
                            <DemandGraph data={customerGraphData['CSEGMENT5']} tableName={"Customer Sporadicity"} />
                            <DemandGraph data={locationGraphData['LSEGMENT5']} tableName={"Location Sporadicity"} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Summary